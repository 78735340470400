import { CalendarDate, getLocalTimeZone, today } from "@internationalized/date";
import { Stack } from "@mui/material";
import { RangeCalendar } from "@nextui-org/calendar";
import { Button, RangeValue } from "@nextui-org/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import LoadingPage from "../../components/common/LoadingPage";
import RoundedMultipleChoices from "../../components/forms/RoundedMultipleChoices";
import NavBar from "../../components/pages/home/Navbar";
import SearchInput from "../../components/SearchBar";
import { useScreen } from "../../hooks/useScreen";
import { useUser } from "../../hooks/useUser";
import { getTimeDifferenceInternationalTimes } from "../../libs/utils/date.util";
import { countries } from "../../models/countries";

const CALENDAR_OFFSET = 40;
const TRIP_MATE_OPTIONS = ["Solo", "Lover", "Friends", "Family"];
const MAXIMUM_DAYS = 7;

type FormFields = {
  currentCountry: string;
  tripMate: string[];
};

const ItineraryInformationPageI = () => {
  const [searchParams] = useSearchParams();
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const { screenWidth } = useScreen();

  const country = searchParams.get("country");

  const [tripDate, setTripDate] = useState<RangeValue<CalendarDate>>({
    start: today(getLocalTimeZone()),
    end: today(getLocalTimeZone()).add({ weeks: 1 }),
  });

  const { register, watch, setValue, handleSubmit } = useForm<FormFields>({
    defaultValues: {
      currentCountry: country || "",
      tripMate: [TRIP_MATE_OPTIONS[0]],
    },
  });

  const { tripMate, currentCountry } = watch();
  const totalDays = getTimeDifferenceInternationalTimes(
    tripDate.start,
    tripDate.end
  );
  const doesExceedDayLimit = totalDays > MAXIMUM_DAYS;

  function onSubmit() {
    try {
      if (!currentCountry) return toast.error("Please select a country");

      if (!currentCountry) return toast.error("Please fill all the forms");

      if (!countries.includes(currentCountry))
        return toast.error(`${currentCountry} is not a valid city`);

      if (doesExceedDayLimit)
        return toast.error("The trip must be less than 7 days");

      if (tripMate.length < 1)
        return toast.error("Please select at least one trip mate");

      navigate(
        `/create-itinerary?country=${currentCountry}&days=${totalDays}&tripMate=${tripMate.join(
          ","
        )}&step=2&date=${tripDate.start}`
      );
    } catch (error) {
      toast.error(error.message);
    }
  }

  if (loading) return <LoadingPage message="Loading..." />;

  if (!user) {
    setTimeout(() => {
      navigate("/sign-in");
    }, 1000);

    return null;
  }

  return (
    <Stack className="flex flex-col gap-y-10 lg:gap-y-16 items-left lg:items-center w-[90%] lg:w-[40%] my-0 pt-2 mx-auto h-[80%] text-black">
      <NavBar user={user} />
      <form onSubmit={handleSubmit(onSubmit)} className="w-full">
        <h1 className="text-title leading-7 font-semibold lg:w-max w-4/5 mb-7">
          Tell us how you'd like your trip to be
        </h1>
        <Stack width={"100%"} spacing={2}>
          <SearchInput
            value={currentCountry}
            inputElement={
              <label className="form-control w-full">
                <div className="label">
                  <span className="label-text">
                    Where would you love to explore next?
                  </span>
                </div>
                <input
                  {...register("currentCountry")}
                  type="text"
                  placeholder="Ex. Sydney, Time Square"
                  className="input input-bordered w-full text-sm"
                />
              </label>
            }
            onSelect={(country: string) => setValue("currentCountry", country)}
            items={countries}
          />
          <RangeCalendar
            calendarWidth={screenWidth - CALENDAR_OFFSET}
            bottomContent={
              <p
                className={`w-full text-center text-sm pb-4 ${
                  doesExceedDayLimit
                    ? "text-red-500 font-light animate-error-shake"
                    : ""
                }`}
              >
                {doesExceedDayLimit
                  ? "The trip must be less than 7 days"
                  : `Total Days :  ${totalDays}`}
              </p>
            }
            aria-label="Date (Uncontrolled)"
            minValue={today(getLocalTimeZone())}
            value={tripDate}
            onChange={setTripDate}
          />
          <RoundedMultipleChoices
            options={TRIP_MATE_OPTIONS}
            currentValue={tripMate}
            setter={(newValue: string[]) => setValue("tripMate", newValue)}
            label="Who's joining your trip?"
          />

          <Button
            className="bg-primary h-[56px] rounded-full text-buttonTextColor font-semibold"
            type="submit"
            fullWidth
          >
            Continue
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default ItineraryInformationPageI;
