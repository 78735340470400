import axios from "axios";
import { Destination } from "../models/destination";

export async function fetchGooglePlaces(
  query: string,
  fields: string[],
  targetCountry: string | null = null
) {
  try {
    let finalDestinations: any;
    const requiredFields = fields.map((field) => `places.${field}`).join(",");
    const response = await axios.post(
      process.env.GOOGLE_API_ENDPOINT!,
      {
        textQuery: query,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "X-Goog-Api-Key": process.env.GOOGLE_API_KEY,
          "X-Goog-FieldMask": requiredFields,
        },
      }
    );

    const { places } = response.data;

    // Filter the places by the target country
    finalDestinations = targetCountry
      ? await filterPlacesByCountry(places, targetCountry)
      : places;

    const serialized_destinations = finalDestinations.map((place: any) =>
      serialize_destination(place)
    );

    return serialized_destinations;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

async function filterPlacesByCountry(places: any[], targetCountry: string) {
  const filteredPlaces: any[] = [];

  for (const place of places) {
    if (!Object.keys(place).includes("compoundCode")) {
      continue;
    }

    const country = place.plusCode.compoundCode.split(",")[1].trim();
    if (country === targetCountry) {
      filteredPlaces.push(place);
    }
  }

  return filteredPlaces;
}

export function getPhotoReference(photo: any) {
  return photo.name.split("/")[3];
}

export function getDestinationImageUrl(
  maxWidth: number = 1000,
  maxHeight: number = 1000,
  photoRef: string
) {
  const endpoint = `https://maps.googleapis.com/maps/api/place/photo?photo_reference=${photoRef}&maxwidth=${maxWidth}&maxheight=${maxHeight}&key=${process.env.GOOGLE_API_KEY}`;
  return endpoint;
}

export async function getDestinationLatLng(placeId: string) {
  try {
    const url = `https://places.googleapis.com/v1/places/${placeId}`;
    const headers = {
      "Content-Type": "application/json",
      "X-Goog-Api-Key": process.env.GOOGLE_API_KEY,
      "X-Goog-FieldMask": "location,formattedAddress",
    };
    const response = await axios.get(url, { headers });

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

function serialize_destination(destination: any): Destination {
  let img_cover: string;
  const {
    id,
    displayName,
    formattedAddress,
    editorialSummary,
    primaryType,
    photos,
  } = destination;
  const [
    name_place,
    location,
    open,
    close,
    description,
    special_events,
    high_level_events,
    category,
    place_id,
  ] = [
    displayName.text,
    formattedAddress,
    null,
    null,
    editorialSummary ? editorialSummary.text : null,
    null,
    null,
    primaryType,
    id,
  ];

  if (!photos) {
    img_cover = "";
  } else if (photos.length <= 0) {
    img_cover = "";
  } else {
    img_cover = getDestinationImageUrl(
      1000,
      1000,
      getPhotoReference(photos[0])
    );
  }

  return {
    name_place,
    location,
    open,
    close,
    description,
    img_cover,
    special_events,
    high_level_events,
    category,
    place_id,
  };
}
