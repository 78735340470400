import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import DestinationItem from "./DestinationItem";
import Header from "./Header";
import LoadingDestinations from "./LoadingDestinations";

const SuggestedDestinations = ({ itinerary, currentDay }) => {
  const {
    data: destinations,
    isPending,
    isError,
    refetch,
    isRefetching,
  } = useQuery({
    queryKey: ["destinations", itinerary.city, itinerary.categories],
    queryFn: async () => {
      const city = itinerary.city.replace(", ", "_");
      const categories = itinerary.categories.reduce(
        (accu, val) => accu + "," + val
      );

      const {
        data: { destinations },
      } = await axios.get(
        `${process.env.URPLANTRIP_GENERATOR_ENDPOINT}/${process.env.API_VERSION}/destinations/suggests/${city}/${categories}`
      );
      return destinations;
    },
    staleTime: 0,
  });

  if (isError)
    return <h1 className="text-sm">Could not suggest you more destinations</h1>;

  return (
    <>
      <Header onRefresh={() => refetch()} />
      {!(isPending || isRefetching) ? (
        <div className="w-full rounded-lg flex items-center justify-between gap-4 overflow-x-scroll my-2">
          {destinations
            ? destinations.map((destination) => {
                return (
                  <DestinationItem
                    key={destination["name_place"]}
                    currentDay={currentDay}
                    destination={destination}
                  />
                );
              })
            : null}
        </div>
      ) : (
        <div className="flex gap-x-3 w-full overflow-x-scroll">
          {Array.from({ length: 5 }, (_, index) => {
            return <LoadingDestinations key={index} />;
          })}
        </div>
      )}
    </>
  );
};

export default SuggestedDestinations;
