import React, { FC } from "react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { User } from "@supabase/supabase-js";
import { useNavigate } from "react-router";
import { FaSignOutAlt } from "react-icons/fa";
import { signOut } from "../../helper/auth";
import toast from "react-hot-toast";

type Props = {
  user: User | null;
};

const UserProfile: FC<Props> = ({ user }) => {
  const navigate = useNavigate();

  async function handleSignOut() {
    toast.loading("We are signing you out");
    await signOut();
    toast.dismiss();
    toast.success("You have been signed out");
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  function handleLogin() {
    navigate("/sign-in");
  }
  return user ? (
    <FaSignOutAlt size={20} onClick={handleSignOut} />
  ) : (
    <UserCircleIcon onClick={handleLogin} className="size-7" />
  );
};

export default UserProfile;
