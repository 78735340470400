import React, { useContext } from "react";
import MapView from "../../../components/pages/itinerary/MapView";
import { ItineraryContext } from "../../../contexts/ItineraryContext";
import Cover from "./Cover";
import ItineraryList from "./ItineraryList";

function DesktopLayout({}) {
  const { currentSelectedDay } = useContext(ItineraryContext);
  return (
    <div className="w-full h-full hidden lg:flex gap-y-5 lg:gap-y-0">
      <div className="lg:w-1/2 w-full h-full box-border">
        {currentSelectedDay !== null ? <MapView /> : <Cover />}
      </div>
      <div className="lg:w-1/2 w-full h-full overflow-y-scroll no-scrollbar">
        <ItineraryList />
      </div>
    </div>
  );
}

export default DesktopLayout;
