import { Stack } from "@mui/material";
import { Button } from "@nextui-org/react";
import { useQuery } from "@tanstack/react-query";
import axios, { isAxiosError } from "axios";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import LoadingPage from "../components/common/LoadingPage";
import Title from "../components/common/Title";
import NavBar from "../components/pages/home/Navbar";
import CategoryCard from "../components/pages/itineraryForm/CategoryCard";
import { useUser } from "../hooks/useUser";

const CreateItinerary = () => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const { user, loading } = useUser();
  const [error, setError] = useState({
    message: "",
    isError: false,
  });
  const [searchParams] = useSearchParams();
  const currentCountry = searchParams.get("country");
  const date = searchParams.get("date");
  const days = searchParams.get("days");
  const navigate = useNavigate();
  const { data: categories, isPending: isLoading } = useQuery({
    queryKey: ["categories", currentCountry],
    queryFn: async () => {
      try {
        if (!currentCountry) return;
        const convertedCountryInput = currentCountry.replace(/, /g, "_");
        const { data } = await axios.get(
          `${process.env.URPLANTRIP_GENERATOR_ENDPOINT}/${process.env.API_VERSION}/categories/${convertedCountryInput}`
        );

        if (data.status_code === 400)
          setError((_) => {
            return { message: data.detail, isError: true };
          });

        const categories = Object.keys(data);

        return categories;
      } catch (error: any) {
        if (isAxiosError(error)) {
          toast.error("Could not connect to server");
        } else {
          toast.error(error.message);
        }
        setError((prev) => ({
          message: error.message,
          isError: true,
        }));

        return null;
      }
    },
    enabled: !!currentCountry,
  });

  function handleSelectCategory(category: string) {
    try {
      setSelectedCategories((prev) => {
        if (prev.some((item) => item === category)) {
          return prev.filter((item) => item !== category);
        }

        return [...prev, category];
      });
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  function handleSubmit() {
    try {
      if (selectedCategories.length < 3) {
        toast.error("Please select at least 3 categories");
        return;
      }
      navigate(
        `/confirm-destinations?country=${currentCountry}&categories=${selectedCategories.join(
          ","
        )}&date=${date}&days=${days}`
      );
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  if (!currentCountry) return navigate("/");
  if (isLoading || loading) return <LoadingPage message="Loading..." />;

  if (error.isError) throw new Error("Could not fetch categories");

  if (!user) {
    toast.error("Please login to continue");
    setTimeout(() => {
      navigate("/sign-in");
    }, 1000);

    return null;
  }
  return (
    <div className="h-full w-[90%] lg:w-[40%] mx-auto pt-2 text-black flex flex-col justify-start pb-10">
      <NavBar user={user} />
      <Title text="My Travel Style" position="left" />
      <p className="text-gray-300 text-sm mt-1">
        {"(Choose at least 3 categories)"}
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        {isLoading ? (
          <span className="border-solid border-4 border-r-gray-200 border-t-gray-200 border-purple-500 w-[40px] animate-spin h-[40px] rounded-full mx-auto my-20"></span>
        ) : categories ? (
          <Stack className="mt-6" gap={2}>
            {categories.map((type: string) => (
              <CategoryCard
                title={type}
                key={type}
                onSelect={() => {
                  handleSelectCategory(type);
                }}
                isSelected={selectedCategories.includes(type)}
              />
            ))}
          </Stack>
        ) : (
          <h1 className="text-lg text-center h-[300px] flex items-center justify-center">
            Sorry, but we could not retrieve the categories for your destination
            😢
          </h1>
        )}
      </div>
      <Button
        onClick={handleSubmit}
        className="mt-auto h-[56px] bg-primary font-semibold"
      >
        View my destinations
      </Button>
    </div>
  );
};

export default CreateItinerary;
