import React, { useContext } from "react";
import { ItineraryContext } from "../../../contexts/ItineraryContext";
import destinationTypes from "../../../models/categoryIcons";

const Cover = () => {
  const { itinerary } = useContext(ItineraryContext);
  if (!itinerary) return null;
  const { name, imgCover, categories } = itinerary;

  return (
    <div className="w-full h-[390px] lg:h-full relative rounded-bl-3xl rounded-br-3xl lg:rounded-3xl overflow-hidden">
      <div className="absolute z-[2] left-[30px] bottom-0">
        <div className="flex flex-col gap-y-3">
          <p className="text-lg lg:text-2xl text-white font-semibold">
            Your trip to {name}
          </p>
          <ul className="flex gap-x-3 gap-y-2 overflow-x-hidden mb-4">
            {categories?.map((category) => (
              <div
                key={category}
                className="bg-white w-10 h-10 rounded-full flex items-center justify-center"
              >
                <p>{destinationTypes[category]}</p>
              </div>
            ))}
          </ul>
        </div>
      </div>

      <div className="w-full h-full z-1 absolute bg-gradient-to-b from-gray-500 to-black opacity-60 lg:rounded-3xl"></div>
      <img className="w-full h-full object-cover" src={imgCover} />
    </div>
  );
};

export default Cover;
