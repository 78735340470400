import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ItineraryContext } from "../../contexts/ItineraryContext.jsx";
import { useStripeCustomerInfo } from "../../hooks/useStripeCustomerInfo.ts";
import BackButton from "./_components/BackButton.tsx";
import DesktopLayout from "./_components/DesktopLayout.tsx";
import ItineraryPDF from "./_components/ItineraryPDF.tsx";
import MobileLayout from "./_components/MobileLayout.tsx";
import PDFButton from "./_components/PDFButton.tsx";

function ItineraryPage() {
  const navigate = useNavigate();

  const { itinerary } = useContext(ItineraryContext);

  const { stripeCustomerInfo } = useStripeCustomerInfo();

  return (
    <div className="relative flex flex-col w-full h-screen lg:h-screen overflow-hidden lg:p-5 bg-[#FCFCFC]">
      <BackButton onBackHome={() => navigate("/")} />
      <PDFButton isMemberShip={stripeCustomerInfo?.plan_active ?? true} />
      {itinerary && <ItineraryPDF isDisplay={true} />}
      <DesktopLayout />
      <MobileLayout />
    </div>
  );
}

export default ItineraryPage;
