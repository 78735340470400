export const countries = [
  "Thailand, Bangkok",
  // "Thailand, Chiangmai",
  "Korea, Seoul",
  // "Vietnam, Danang",
  "Japan, Tokyo",
  "Taiwan, Taipei",
  // "Singapore",
  // "Malaysia, KualaLumpur",
  // "Switzerland, Geneva"
]