const ListView = ({
  children,
  gap = 20,
  axis = "column",
  justify = "center",
  align = "center",
  scrollable = true,
  minHeight = "auto",
  maxHeight = "auto",
}) => {
  const container = {
    overflowX: `${
      axis === "row" ? (scrollable ? "scroll" : "hidden") : "hidden"
    }`,
    overflowY: `${
      axis === "column" ? (scrollable ? "scroll" : "hidden") : "hidden"
    }`,
    display: "flex",
    flexDirection: `${axis}`,
    rowGap: `${axis === "column" ? `${gap}` : "0"}px`,
    columnGap: `${axis === "row" ? `${gap}` : "0"}px`,
    justifyContent: justify,
    alignItems: align,
    padding: "5px 0",
    minHeight: `${minHeight}${minHeight === "auto" ? "" : "px"}`,
    maxHeight: `${maxHeight}${maxHeight === "auto" ? "" : "px"}`,
    width: "100%",
  };
  return <div style={container}>{children}</div>;
};

export default ListView;
