import React from "react";
import toast from "react-hot-toast";
import { FaFilePdf } from "react-icons/fa";
import { getPdfFromHTML } from "../../../helper/pdf.helper";

const PDFButton = ({ isMemberShip }) => {
  async function handleGeneratePDF() {
    try {
      if (!isMemberShip)
        return toast.error("Upgrade to pro to download as PDF", {
          duration: 2000,
        });
      let itineraryElement = document.querySelector(
        "#itinerary"
      ) as HTMLElement;
      const doc = await getPdfFromHTML(itineraryElement);
      toast.success("Successfully downloaded the PDF");

      setTimeout(() => {
        doc.save();
      }, 1500);
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <button
      onClick={handleGeneratePDF}
      className="rounded-full overflow-hidden bg-white w-[45px] h-[45px] flex items-center justify-center absolute z-10 lg:right-1/2 lg:top-10 lg:mr-[20px] p-0 right-0 top-5 mr-[20px] shadow-xl"
    >
      <FaFilePdf className="text-primary" size={17} />
    </button>
  );
};

export default PDFButton;
