import { createContext, useEffect, useState } from "react";
import { useQueryParam } from "../hooks/useQueryParam";
import { useFetchTrip } from "../hooks/useFetchTrip";

export const ItineraryContext = createContext();

export const ItineraryProvider = ({ children }) => {
  const [tripId, view] = [useQueryParam("tripId"), useQueryParam("view")];

  const { trip, loading: loadingTrip } = useFetchTrip(tripId);

  const [itinerary, setItinerary] = useState(null);

  const [currentSelectedDay, setCurrentSelectedDay] = useState(null);

  useEffect(() => {
    if (!trip) return;
    setItinerary(trip.itinerary);
  }, [trip]);

  function handleUpdateItinerary(newItinerary) {
    setItinerary((prev) => ({
      ...prev,
      itinerary: newItinerary,
    }));
  }

  return (
    <ItineraryContext.Provider
      value={{
        tripId,
        itinerary,
        trip,
        view,
        currentSelectedDay,
        setCurrentSelectedDay,
        handleUpdateItinerary,
        loadingTrip,
      }}
    >
      {children}
    </ItineraryContext.Provider>
  );
};
