import axios from "axios";

export const itineraryManagementClient = axios.create({
  baseURL: `${process.env.ITINERARY_MANAGEMENT_ENDPOINT}/v1`,
});

itineraryManagementClient.interceptors.response.use((response) => {
    console.error(response)
  if (response.status !== 200) {
    throw new Error(response.data.error.message);
  }
  return response;
});
