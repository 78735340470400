import { Button } from "@nextui-org/react";
import React, { FC } from "react";
import toast from "react-hot-toast";
import { capitalizeFirstLetter } from "../../libs/utils/misc.util";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { Provider } from "@supabase/supabase-js";
import { signInWithOAuth } from "../../helper/auth";
import { useNavigate } from "react-router";

type Props = {
  provider: Provider | "email";
};

const OAuthButton: FC<Props> = ({ provider }) => {
  const navigate = useNavigate();

  async function handleLogin() {
    if (provider === "email") {
      navigate("/sign-in/email");
      return;
    }

    await signInWithOAuth(provider);
  }

  return (
    <Button
      onClick={handleLogin}
      className="border-solid border-black rounded-full flex items-center justify-start h-[46px] gap-x-3"
      variant="bordered"
      fullWidth
    >
      {provider !== "email" ? (
        <img
          src={`/images/oauth/${provider}.png`}
          className="h-[24px] w-[24px] object-contain"
        />
      ) : (
        <EnvelopeIcon className="size-6" />
      )}
      <p className="font-semibold">
        Continue with {capitalizeFirstLetter(provider)}
      </p>
    </Button>
  );
};

export default OAuthButton;
