import { jsPDF } from "jspdf";

// update time of a destination in a specified day
export function updateItineraryTime(itinerary, day, originTime, targetTime) {
  // Deep copy the itinerary
  const deepItinerary = JSON.parse(JSON.stringify(itinerary));
  // Delete previous time key
  delete deepItinerary[day][originTime];
  // Insert new key with the same place
  deepItinerary[day][targetTime] = itinerary[day][originTime];
  // Sort the deepItinerary
  deepItinerary[day] = Object.fromEntries(
    Object.entries(deepItinerary[day]).sort((first, second) => {
      // first and second represent => ["09:00", {...destinationDetail}]
      // first[0] and second[0] represent the time
      const timeFirst = first[0];
      const timeSecond = second[0];
      // compare time
      return timeFirst.localeCompare(timeSecond);
    })
  );

  return deepItinerary;
}

function isObjectSame(object1, object2) {
  return JSON.stringify(object1) === JSON.stringify(object2);
}

export function deleteDestination(itinerary, targetDay, targetDestination) {
  let destinationIndex;
  // Deep copy the itinerary
  const deepItinerary = JSON.parse(JSON.stringify(itinerary));
  // Extract entries from deepItinerary
  const entries = Object.entries(deepItinerary);
  // Find the index of the target destination from the itinerary
  entries.map((currentDay) => {
    // Find destinationIndex
    if (currentDay[0] === targetDay) {
      const destinations = currentDay[1];
      const destinationEntries = Object.entries(destinations);
      // Find a place which is the same as targetDestination
      const index = destinationEntries.findIndex((destination) =>
        isObjectSame(destination[1], targetDestination)
      );
      destinationIndex = index;
    }
  });
  // Extract the time to be used as a key to delete destination index
  const keyTime = Object.keys(deepItinerary[targetDay])[destinationIndex];
  // Delete targetDestination from deepItinerary using destinationIndex
  delete deepItinerary[targetDay][keyTime];

  return deepItinerary;
}

export function addDestination(itinerary, newDestination, day) {
  const rightNow = new Date();
  const [hour, minute] = [rightNow.getHours(), rightNow.getMinutes()];
  let formattedTime = `${hour}:${minute}`;

  // If the time is already taken, increment the time by 1 minute
  while (Object.keys(itinerary[day]).includes(formattedTime)) {
    const [h, m] = formattedTime.split(":").map((time) => parseInt(time));
    if (m === 59) {
      formattedTime = `${h + 1}:00`;
    } else {
      formattedTime = `${h}:${m + 1}`;
    }
  }

  // Deep copy the itinerary
  const deepItinerary = JSON.parse(JSON.stringify(itinerary));
  const targetDay = deepItinerary[day];
  const result = {
    ...deepItinerary,
    [day] : {
      ...targetDay,
      [formattedTime]: {
        ...newDestination,
        special_events:
          Object.keys(newDestination).includes("special_events") &&
          newDestination["special_events"]
            ? newDestination["special_events"].split(",")
            : "",
      },
    }
  };
  return result;
}

