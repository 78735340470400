import { CalendarDate, getLocalTimeZone } from "@internationalized/date";

const DAY = 24 * 60 * 60 * 1000;

export function formatTripCardDate(dateFromItinerary: string) {
  // Ex : 2024-08-21
  const [year, month, day] = dateFromItinerary.split("-");
  return `${day}/${month}/${year}`;
}

export function addDaysForTripCardDate(
  dateFromItinerary: string,
  days: number,
  format: "string" | "date"
) {
  // Ex : 2024-08-21
  if (typeof dateFromItinerary !== "string")
    throw new Error("Invalid dateFromItinerary");
  if (typeof days !== "number") throw new Error("Invalid days");
  const date = new Date(dateFromItinerary);
  date.setDate(date.getDate() + days);
  const newYear = date.getFullYear();
  const newMonth = date.getMonth() + 1;
  const newDay = date.getDate();
  return format === "string" ? `${newDay}/${newMonth}/${newYear}` : date;
}

export function getTimeDifferenceForTripCard(
  dateFromItinerary: string,
  targetDate: Date
): number {
  const date = new Date(dateFromItinerary);
  const difference = date.getTime() - targetDate.getTime();
  const daysDifference = Math.floor(difference / DAY);
  return daysDifference;
}

export function getTimeDifferenceInternationalTimes(
  startDate: CalendarDate,
  endDate: CalendarDate
) {
  const difference =
    endDate.toDate(getLocalTimeZone()).getTime() -
    startDate.toDate(getLocalTimeZone()).getTime();
  const daysDifference = Math.floor(difference / DAY);
  return daysDifference;
}
