import { useQuery } from "@tanstack/react-query";
import { useUser } from "./useUser";
import {
  createStripeCustomerInfo,
  doesStripeCustomerExist,
  getStripeCustomerInfo,
} from "../libs/services/stripeCustomers.service";
import { StripeCustomer } from "../models/stripCustomer.model";

export function useStripeCustomerInfo() {
  const { user, loading } = useUser();
  const { data: stripeCustomerInfo, isPending: isLoading } = useQuery({
    queryKey: ["user", user?.id, loading],
    queryFn: async () => {
      if (!user) return null;

      const doesUserExist = await doesStripeCustomerExist(user.id);

      let userInfo: StripeCustomer;

      if (doesUserExist) {
        userInfo = await getStripeCustomerInfo(user.id);
      } else {
        userInfo = await createStripeCustomerInfo(user.id);
      }

      return userInfo;
    },
    enabled: !!user?.id,
  });

  return { stripeCustomerInfo, isLoading };
}
