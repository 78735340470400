import React, { FC, ReactNode } from "react";
import { Tag } from "../Tag";
import { Stack } from "@mui/material";
import { Button } from "@nextui-org/react";
import Rating from "../../common/Rating";

type Props = {
  destination: any;
  onClick?: () => void;
};

export const Destination: FC<Props> = ({ destination, onClick }) => {
  const categories = destination.category?.split(",") ?? [];
  return (
    <div className="flex gap-x-[5px] items-center relative w-full p-4 border border-solid rounded-2xl">
      <Detail>
        <Stack justifyContent={"space-between"} direction={"row"}>
          <h4 className="font-semibold">{destination["name_place"]}</h4>
          {/* TODO : Use real rating */}
          <Rating rating={destination.rating ?? 0} />
        </Stack>
        <Stack direction={"row"} spacing={1}>
          {categories?.map((category: string, index: number) => {
            return <Tag key={index} content={category} />;
          })}
        </Stack>
        <Button
          onClick={onClick}
          className="bg-primary h-[30px] w-max rounded-lg text-white absolute bottom-4 right-4"
        >
          Detail
        </Button>
      </Detail>
    </div>
  );
};

type DetailProps = {
  children: ReactNode;
};

const Detail: FC<DetailProps> = ({ children }) => {
  return (
    <div className="flex flex-col gap-y-[5px] w-full justify-center">
      {children}
    </div>
  );
};
