import React, { useContext } from "react";
import toast from "react-hot-toast";
import Collapsable from "../../../components/Collapsable";
import DestinationEdit from "../../../components/DestinationEdit";
import { GoogleDestinationSearchBar } from "../../../components/GoogleDestinationSearchBar";
import Transportation from "../../../components/itinerary/Transportation";
import ListView from "../../../components/ListView.";
import { ItineraryContext } from "../../../contexts/ItineraryContext";
import {
  addDestination,
  updateItineraryTime,
} from "../../../helper/itinerary.helper";
import { updateTripById } from "../../../models/itinerary.model";
import SuggestedDestinations from "./SuggestedDestinations";

const ItineraryList = () => {
  const {
    itinerary,
    tripId,
    handleUpdateItinerary,
    setCurrentSelectedDay,
    currentSelectedDay,
  } = useContext(ItineraryContext);

  if (!itinerary) return null;

  async function handleAddGoogleDestination(destination, currentDay) {
    try {
      if (!destination) throw new Error("Please select a destination");

      if (!currentDay) throw new Error("Please select a day");

      const doesDestinationExist = Object.values(
        itinerary.itinerary[currentDay]
      ).find((dest: any) => dest.place_id === destination.place_id);

      if (doesDestinationExist)
        throw new Error("You have already added this place");

      const updatedItinerary = addDestination(
        itinerary.itinerary,
        destination,
        currentDay
      );

      toast.loading("Adding the destination...");

      await updateTripById(tripId, updatedItinerary);

      handleUpdateItinerary(updatedItinerary);

      toast.dismiss();
      // show success message
      toast.success("Added the destination");
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleUpdateDestinationTime(
    currentDay,
    originTime,
    targetTime
  ) {
    try {
      const updatedItinerary = updateItineraryTime(
        itinerary.itinerary,
        currentDay,
        originTime,
        targetTime
      );

      await updateTripById(tripId, updatedItinerary);

      handleUpdateItinerary(updatedItinerary);

      toast.success("Successfully edited the time");
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <div className="m-0 px-[10px] lg:px-[20px] h-screen overflow-y-scroll p-0 text-black">
      <ul className="flex flex-col gap-y-4 w-full m-0 p-0">
        {Object.keys(itinerary["itinerary"]).map((day, index) => (
          <Collapsable
            index={index}
            isExpanded={day === currentSelectedDay}
            onClick={() => {
              setCurrentSelectedDay((prev) => {
                return prev === day ? null : day;
              });
            }}
            key={day}
            title={day}
          >
            <ListView align="flex-start">
              {Object.values(itinerary["itinerary"][day]).map(
                (destination: any, index) => {
                  const destinations: any[] = Object.values(
                    itinerary["itinerary"][day]
                  );

                  return (
                    <div
                      className="flex flex-col gap-y-3 w-full"
                      key={destination.name_place}
                    >
                      <DestinationEdit
                        itinerary={itinerary.itinerary}
                        isTrashVisible={true}
                        key={destination["name_place"]}
                        currentDay={day}
                        onUpdateItineraryTime={handleUpdateDestinationTime}
                        time={Object.keys(itinerary["itinerary"][day])[index]}
                        destination={destination}
                        tripId={tripId}
                        onClick={() => {}}
                      />

                      {index < destinations.length - 1 ? (
                        <Transportation
                          originPlaceId={destination.place_id}
                          targetPlaceId={destinations[index + 1].place_id}
                        />
                      ) : null}
                    </div>
                  );
                }
              )}
            </ListView>
            <div className="my-[15px]">
              <GoogleDestinationSearchBar
                targetCountry={itinerary.country}
                currentDay={day}
                onAddDestination={handleAddGoogleDestination}
              />
            </div>
            <div className="mt-5">
              <SuggestedDestinations currentDay={day} itinerary={itinerary} />
            </div>
          </Collapsable>
        ))}
      </ul>
    </div>
  );
};
export default ItineraryList;
