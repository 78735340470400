import { v4 } from "uuid";
import { supabase } from "../../configs/db";
import { StripeCustomer } from "../../models/stripCustomer.model";

// ===================== CREATE =====================
export async function createStripeCustomerInfo(userId: string) {
  try {
    console.log("Creating stripe customer info");
    const { data, error } = await supabase
      .from("stripe_customers")
      .insert({
        id: v4(),
        user_id: userId,
        stripe_customer_id: null,
        plan_active: false,
        plan_expires: null,
        subscription_id: null,
      })
      .select()
      .single();

    if (error) throw new Error(error.message);
      console.log("Stripe customer info created")
    return data as StripeCustomer;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

// ===================== READ =====================

export async function getStripeCustomerInfo(userId: string) {
  try {
    const { data, error } = await supabase
      .from("stripe_customers")
      .select("*")
      .eq("user_id", userId)
      .single();

    if (error) throw new Error(error.message);

    return data as StripeCustomer;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function doesStripeCustomerExist(userId: string): Promise<boolean> {
  try {
    const { data, error } = await supabase
      .from("stripe_customers")
      .select("*")
      .eq("user_id", userId);

    if (error) throw new Error(error.message);

    return data.length > 0;
  } catch (error: any) {
    throw new Error(error.message);
  }
}
