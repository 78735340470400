import React, { useContext, useEffect, useState } from "react";
import {
  MapViewContext,
  MapViewProvider,
} from "../../../contexts/MapViewContext";
import { getDestinationLatLng } from "../../../helper/googlePlace.helper";
import Mapbox from "../../map/Mapbox";

const MapView = () => {
  return (
    <MapViewProvider>
      <main className="lg:h-full h-[390px] w-full flex gap-x-3 box-border overflow-hidden">
        <MapBackground />
      </main>
    </MapViewProvider>
  );
};

const MapBackground = () => {
  const { destinations } = useContext(MapViewContext);
  const [serializedDestinations, setSerializedDestinations] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const fetcedDestinationsWithLocations = destinations.map(
        async (destination) => {
          const placeId = destination.place_id;
          // fetch Lat Lng of the place
          const {
            location: { latitude, longitude },
          } = await getDestinationLatLng(placeId);
          // serialize destination
          const serialized_destination = {
            ...destination,
            location: {
              lat: latitude,
              lng: longitude,
            },
          };
          return serialized_destination;
        }
      );
      const fetcedDestinationsWithLocationsPromise = await Promise.all(
        fetcedDestinationsWithLocations
      );

      setSerializedDestinations((_) => fetcedDestinationsWithLocationsPromise);
    };

    fetchLocations();
  }, [destinations]);

  if (serializedDestinations.length <= 0)
    return (
      <main className="relative w-full h-[450px]">
        <div className="absolute top-[35%] translate-y-[-50%] left-1/2 w-[40px] h-[40px] translate-x-[-50%]">
          <div className="w-full h-full border-[4px] border-solid border-r-blue-500 animate-spin rounded-full "></div>
        </div>
      </main>
    );

  return (
    <>
      <aside className="relative w-full h-full rounded-3xl overflow-hidden">
        <Mapbox
          zoom={9}
          destinations={serializedDestinations}
          lat={serializedDestinations[0].location.lat}
          lng={serializedDestinations[0].location.lng}
        />
      </aside>
    </>
  );
};

export default MapView;
