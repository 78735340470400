import { Provider, User } from "@supabase/supabase-js";
import { supabase } from "../configs/db";

export async function signUp(credentials: {
  email: string;
  password: string;
}): Promise<User | null> {
  try {
    const { email, password } = credentials;

    let {
      data: { user },
      error,
    } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: "/",
      },
    });

    if (error) throw new Error(error.message);

    return user;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function signIn(credentials: { email: string; password: string }) {
  try {
    const { email, password } = credentials;

    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) throw new Error(error.message);
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function signOut() {
  try {
    const { error } = await supabase.auth.signOut();

    if (error) throw new Error(error.message);
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function signInWithOAuth(provider: Provider) {
  try {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: provider,
      options: {
        redirectTo: process.env.OAUTH_REDIRECT_URL!,
      },
    });

    if (error) throw new Error(error.message);
  } catch (error: any) {
    throw new Error(error.message);
  }
}
