import { css } from "@emotion/css";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import { v4 as uuidv4 } from "uuid";
import LoadingPage from "../../components/common/LoadingPage";
import BottomSheetDestination from "../../components/itinerary/destination/BottomSheetDestination";
import { Destination } from "../../components/itinerary/destination/Destination";
import NavBar from "../../components/pages/home/Navbar";
import { useUser } from "../../hooks/useUser";
import { coverImages } from "../../models/coverImages";
import { saveItinerary } from "../../models/itinerary.model";

const tripNameToCountry = {
  "Korea, Seoul": "Korea",
  "Vietnam, Danang": "Vietnam",
  "Japan, Tokyo": "Japan",
  "Taiwan, Taipei": "Taiwan",
  Singapore: "Singapore",
  "Malaysia, KualaLumpur": "Malaysia",
  "Switzerland, Geneva": "Switzerland",
};

const ConfirmDestinationsPage = () => {
  const navigate = useNavigate();
  const [destinations, setDestinations] = useState<
    { time: string; destination: any }[] | null
  >(null);
  const [itinerary, setItinerary] = useState(null);
  const [searchParams] = useSearchParams();

  const [currentDate, currentCountry, numOfDays, selectedTypes] = [
    searchParams.get("date"),
    searchParams.get("country"),
    searchParams.get("days"),
    searchParams.get("categories")?.split(","),
  ];

  const { user, loading: isLoadingUser } = useUser();

  const [selectedDestinationId, setSelectedDestinationId] = useState<
    string | null
  >(null);

  const selectedDestination = destinations?.find(
    (destination) => destination.destination.place_id === selectedDestinationId
  );

  const {
    data: itineraryDetail,
    isFetching: isFetchingDestinationList,
    isError,
    error: errorMessage,
    refetch: refreshDestinationList,
  } = useQuery({
    queryKey: [
      "fetch itinerary detail",
      currentCountry,
      numOfDays,
      selectedTypes,
      currentDate,
    ],
    queryFn: async () => {
      if (!currentCountry || !currentDate || !numOfDays || !selectedTypes)
        return null;

      const selectedCountry = currentCountry.replace(/, /g, "_");

      const searchParams = new URLSearchParams({
        city: selectedCountry,
        days: numOfDays,
        categories: selectedTypes.join(","),
        is_kid_friendly: "true",
        is_old_friendly: "true",
      });

      const endpoint = `${process.env.URPLANTRIP_GENERATOR_ENDPOINT}/${process.env.API_VERSION}/itinerary`;

      const response = await axios.get(
        new URL(`?${searchParams.toString()}`, endpoint).toString()
      );

      if (response.status !== 200) {
        throw new Error(response.data.message);
      }

      return response.data;
    },
  });

  useEffect(() => {
    if (!Boolean(itineraryDetail)) return;

    setDestinations((_) => {
      var destinations: { time: string; destination: any }[] = [];
      for (const day in itineraryDetail) {
        for (const time in itineraryDetail[day]) {
          const destination = itineraryDetail[day][time];
          destinations.push({ time, destination });
        }
      }
      return [...destinations];
    });

    setItinerary((_) => itineraryDetail);
  }, [itineraryDetail]);

  async function handleAddItinerary(trip: any) {
    try {
      if (!user) throw Error("Please login to save your itinerary");
      await saveItinerary(user.id, trip);
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  async function handleCreateTrip() {
    try {
      if (errorMessage) return;

      if (!currentCountry) return;

      const highLevelInformation = {
        name: currentCountry,
        date: currentDate,
        duration: numOfDays,
        imgCover: coverImages[currentCountry],
        city: currentCountry,
        categories: selectedTypes,
        country: tripNameToCountry[currentCountry],
      };

      toast.loading("Saving your trip...");

      await handleAddItinerary({
        ...highLevelInformation,
        itinerary,
      });

      toast.dismiss();

      toast.success("Successfully created your trip");

      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    } catch (error: any) {
      toast.dismiss();
      toast.error(error.message);
    }
  }

  if (!currentCountry || !currentDate || !numOfDays || !selectedTypes)
    return <h1>This link is invalid</h1>;

  if (isLoadingUser) return <LoadingPage message="Loading..." />;

  if (!user) {
    toast.error("Please login to continue");
    setTimeout(() => {
      navigate("/sign-in");
    }, 1000);
    return null;
  }

  return (
    <div className="overflow-y-scroll h-full w-auto lg:w-4/6 mx-auto px-5 flex flex-col gap-y-[20px] text-black">
      <NavBar user={user} />
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        className="w-full"
      >
        <Stack gap={0.5}>
          <h1 className="text-title font-semibold">My Destinations</h1>
          <p className="text-gray-400 text-sm">(You can edit in next page)</p>
        </Stack>
        <ArrowPathIcon
          onClick={() => refreshDestinationList()}
          className="size-6"
        />
      </Stack>
      {isFetchingDestinationList ? (
        <LoadingPage message="Curating more destinations..." />
      ) : errorMessage ? (
        <div
          className={css`
            height: 300px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `}
        >
          <h1
            className={css`
              font-size: 1.2rem;
              font-weight: 500;
            `}
          >
            {errorMessage.message}
          </h1>
        </div>
      ) : (
        <Stack spacing={2} className="lg:p-2 overflow-y-scroll" maxHeight={430}>
          {destinations?.map((destination) => (
            <Destination
              onClick={() =>
                setSelectedDestinationId(destination.destination.place_id)
              }
              key={uuidv4()}
              destination={destination["destination"]}
            />
          ))}
        </Stack>
      )}
      <button
        onClick={handleCreateTrip}
        className="bg-primary rounded-full h-[58px] text-buttonTextColor text-sm"
      >
        Get the plan
      </button>
      <BottomSheet
        open={selectedDestinationId !== null}
        onDismiss={() => {
          setSelectedDestinationId((_) => null);
        }}
        maxHeight={500}
      >
        {selectedDestination && (
          <BottomSheetDestination
            destination={selectedDestination.destination}
          />
        )}
      </BottomSheet>
    </div>
  );
};

const RegenerateButton = ({ icon, fetchData }) => {
  return (
    <div
      className="text-primary cursor-pointer"
      onClick={(_) => {
        fetchData();
      }}
    >
      {icon}
    </div>
  );
};

export default ConfirmDestinationsPage;
