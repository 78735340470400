import { useEffect, useState } from "react"
import { fetchTripById } from "../models/itinerary.model";
import { toast } from "react-hot-toast";

export const useFetchTrip = (tripId : string, dependencies = [null]) => {
  const [trip, setTrip] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(()=>{
    const fetchtrip = async () => {
      try {
        const fetchedTrip = await fetchTripById(tripId);
        setTrip(fetchedTrip);
      } catch (error : any) {
        console.error(error.message)
        toast.error(error.message)
      } finally {
        setLoading(false);
      }
    }

    fetchtrip()
  }, [...dependencies])

  return {trip, loading}
}