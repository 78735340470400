import jsPDF from "jspdf";
import { font } from "../assets/fonts/Roboto-Regular-normal";

export async function getPdfFromHTML(
  htmlElement: HTMLElement | null
): Promise<jsPDF> {
  try {
    // Handle empty htmlElement
    if (!htmlElement) throw new Error("HTMLElement not found when getting pdf from HTML");
    // Create new jsPDF
    const doc = new jsPDF("l", "mm", [1500, 1400]);
    // Set font
    doc.addFileToVFS("Roboto-Regular.ttf", font);
    doc.addFont("Roboto-Regular.ttf", "Roboto-Regular", "normal");
    doc.setFont("Roboto-Regular");
    // Return the itinerary
    return new Promise((resolve)=>{
      doc.html(htmlElement, {
        callback: function (finalDoc) {
          resolve(finalDoc)
        },
        x: 12,
        y: 12,
      });
    })
  } catch (error: any) {
    throw new Error(error.message);
  }
}
