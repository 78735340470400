const destinationTypes = {
  "Highlight" : "⭐",
  "Activity" : "🏈",
  "Nature" : "🌳",
  "Local" : "👨‍🌾",
  "Park" : "fast-food",
  "Photospot" : "fast-food",
  "Street" : "fast-food",
  "Temple" : "🛕",
  "Food" : "fast-food",
  "Shopping Mall" : "🛍️",
  "Beaches" : "fish",
  "Natural" : "leaf",
  "Cafe" : "cafe",
  "Activity" : "🏈",
  "Adventure" : "🏕️",
  "Exercise" : "🏋️‍♂️",
  'Market and Mall' : "storefront",
  "Farm and Garden" : "🌾",
  "Zoo" : "🦁",
  "History" : "📜",
  "Tradition" : "📜",
}

export default destinationTypes;