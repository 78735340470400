import React from "react";

const Header = ({ onRefresh }) => {
  return (
    <span className="flex justify-between items-center">
      <p className="font-bold">Recommended destinations</p>
      <p
        onClick={onRefresh}
        className="text-primary text-sm font-semibold cursor-pointer"
      >
        More
      </p>
    </span>
  );
};

export default Header;
