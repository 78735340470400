import { useEffect, useState } from "react";
import { getUser } from "../models/users";
import { User } from "@supabase/supabase-js";

export const useUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userSession = await getUser();
        setUser((_) => userSession);
      } catch (error: any) {
        setUser((_) => null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { user, loading };
};
