import React from "react";
import toast from "react-hot-toast";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    return {
      errorMessage: error,
    };
  }

  render() {
    if (this.state.hasError) {
      return (
        <main className="flex flex-col items-center justify-center h-svh gap-y-3">
          <h1 className="text-xl text-black">
            Oops! Something went wrong 😓
          </h1>
          <button
            onClick={() => {
              window.location.assign("/");
            }}
            className="btn bg-primary text-white shadow-lg"
          >
            Back home
          </button>
        </main>
      );
    }

    return this.props.children;
  }
}
