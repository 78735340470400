import React, { useContext } from "react";
import { ItineraryContext } from "../../../contexts/ItineraryContext";

const ItineraryPDF = ({ isDisplay = false }) => {
  const { itinerary } = useContext(ItineraryContext);

  if (!isDisplay) return null;

  return (
    <div className="absolute translate-x-[1000%] text-black">
      <table id="itinerary" className="flex flex-col gap-y-4 w-screen m-0 p-0">
        <thead className="w-full">
          <tr className="w-full">
            <td>
              <h1 className="text-3xl font-bold">{itinerary.name}</h1>
            </td>
          </tr>
        </thead>
        <tbody>
          {Object.keys(itinerary["itinerary"]).map((day, _) => (
            <tr key={day} className="flex flex-col gap-y-5">
              <td>
                <h2 className="text-xl font-semibold">{day}</h2>
              </td>
              <td className="w-full flex flex-col">
                <div className="flex w-full items-center">
                  <div className="w-[10%] font-semibold border">
                    <p className="p-2">Time</p>
                  </div>
                  <div className="w-[90%] font-semibold border">
                    <p className="p-2">Destination</p>
                  </div>
                </div>
                {Object.values(itinerary["itinerary"][day]).map(
                  (destination: any, index) => {
                    return (
                      <div
                        key={destination.name_place}
                        className="flex w-full items-center"
                      >
                        <p className="w-[10%] border p-2 tracking-wide">
                          {Object.keys(itinerary["itinerary"][day])[index]}
                        </p>
                        <p className="w-[90%] border p-2 tracking-wide">
                          {destination.name_place}
                        </p>
                      </div>
                    );
                  }
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ItineraryPDF;
