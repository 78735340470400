import { motion } from "framer-motion";
import React, { useState } from "react";
import { AppContext } from "../contexts/AppContext.tsx";
import HomePage from "./home/HomePage";

const App = () => {
  const [showBottomSheet, setShowBottomSheet] = useState(true);
  const [showUserProfile, setShowUserProfile] = useState(false);

  return (
    <AppContext.Provider
      value={{
        showBottomSheet,
        showUserProfile,
        setShowBottomSheet,
        setShowUserProfile,
      }}
    >
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <HomePage />
      </motion.div>
    </AppContext.Provider>
  );
};

export default App;
