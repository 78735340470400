import { createContext, useContext, useState, useEffect, useMemo } from "react";
import { ItineraryContext } from "./ItineraryContext";
import { fetchTripById } from "../models/itinerary.model";
import { useQuery } from "@tanstack/react-query";

export const MapViewContext = createContext();

export const MapViewProvider = ({ children }) => {
  const { tripId, currentSelectedDay } = useContext(ItineraryContext);
  // Fetch trip from trip id
  const { data: trip, isPending: isLoadingTrip } = useQuery({
    queryKey: ["trip", tripId],
    queryFn: async () => {
      try {
        return tripId ? await fetchTripById(tripId) : null;
      } catch (error) {
        toast.error(error.message)
      }
    },
    enabled: !!tripId,
  });

  const destinations = useMemo(() => {
    if (!currentSelectedDay || isLoadingTrip || !trip) return [];
    const {itinerary} = trip.itinerary;
    return Object.values(itinerary[currentSelectedDay]);
  }, [trip, currentSelectedDay, isLoadingTrip]);

  return (
    <MapViewContext.Provider
      value={{
        destinations,
      }}
    >
      {children}
    </MapViewContext.Provider>
  );
};
