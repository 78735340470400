import React, { useContext } from "react";
import MapView from "../../../components/pages/itinerary/MapView";
import { ItineraryContext } from "../../../contexts/ItineraryContext";
import Cover from "./Cover";
import ItineraryList from "./ItineraryList";

function MobileLayout({}) {
  const { currentSelectedDay } = useContext(ItineraryContext);
  return (
    <div className="w-full lg:hidden h-full gap-y-5 flex flex-col overflow-hidden">
      {currentSelectedDay !== null ? <MapView /> : <Cover />}
      <ItineraryList />
    </div>
  );
}

export default MobileLayout;
