import React from "react";

function HStack({children, align = 'center', justify = 'center' , gap = 20, onClick, className}){
  const container = {
    display: 'flex',
    flexDirection: 'row',
    gap: `${gap}px`,
    alignItems: align,
    justifyContent : justify,
    height: '50px'
  }
  
  return (
    <div className={className} onClick={onClick} style={container}>
      {children}
    </div>
  )
}

export default HStack;