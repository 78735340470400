import { Stack } from "@mui/material";
import { useContext, useState } from "react";
import { toast } from "react-hot-toast";
import { FaTrashAlt } from "react-icons/fa";
import { PRIMARY_COLOR } from "../assets/colors";
import { ItineraryContext } from "../contexts/ItineraryContext";
import { deleteDestination } from "../helper/itinerary.helper";
import { updateTripById } from "../models/itinerary.model";
import { Tag } from "./itinerary/Tag";

const destinationEdit = {
  display: "flex",
  columnGap: "5px",
  alignItems: "center",
  position: "relative",
  width: "100%",
};


const timeStyle = {
  fontSize: "10px",
  transform: "rotate(-90deg) translateY(-20px)",
  margin: "0",
  padding: "0",
  color: "black",
  backgroundColor: "white",
  minWidth: "50px",
};

const DestinationEdit = ({
  destination,
  time,
  editAble = true,
  onUpdateItineraryTime,
  currentDay,
  isTrashVisible = false,
  displayTime = true,
  itinerary,
  tripId,
  onClick
}) => {
  const [destinationTime, setDestinationTime] = useState(time);
  const { handleUpdateItinerary } = useContext(ItineraryContext);
  const [destinationImage, setDestinationImage] = useState(null);

  function onTimeInput(e) {
    e.stopPropagation();
    try {
      const currentTime = e.target.value;
      const doesCurrentTimeOverlap = Object.keys(itinerary[currentDay]).some(
        (time) => {
          return time === currentTime
        }
      );
      if (doesCurrentTimeOverlap) throw new Error("Cannot have two destinations at the same time");

      setDestinationTime(currentTime);
      onUpdateItineraryTime(currentDay, time, currentTime);
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function handleDeleteDestination() {
    try {
      const doesCurrentHaveOneDestination = Object.keys(itinerary[currentDay]).length <= 1;
      if (doesCurrentHaveOneDestination) throw new Error("Cannot delete the last destination");
      toast.loading("Deleting destination...");
      // Delete destination from the itinerary
      const updatedItinerary = deleteDestination(
        itinerary,
        currentDay,
        destination
      );
      // Update recently updated itinerary to the database
      await updateTripById(tripId, updatedItinerary);

      setTimeout(() => {
        toast.dismiss();

        // Show success message
        toast.success("Successfully deleted the destination");
        // Update the UI
        handleUpdateItinerary(updatedItinerary);
      }, 1000);
    } catch (error) {
      toast.error(error.message);
    }
  }

  const destinationEditImg = {
    width: "92px",
    height: "101px",
    objectFit: "cover",
    borderRadius: "15px",
    transform: displayTime ? "translateX(-35px)" : "translateX(0px)",
  };

  return (
    <div onClick={onClick} style={destinationEdit}>
      {displayTime && (
        <input
          type="time"
          onInput={onTimeInput}
          value={destinationTime}
          style={timeStyle}
          disabled={!editAble}
        />
      )}
      {destinationImage && (
        <img
          className="object-cover"
          onClick={(event) => {
            event.preventDefault();
          }}
          Itinerary
          style={destinationEditImg}
          src={destinationImage}
        />
      )}
      <Detail displayTime={displayTime}>
        <h4 style={{ margin: "0", fontSize: "13px", fontWeight: "600" }}>
          {destination["name_place"]}
        </h4>
        {destination.category !== null ? (
          destination.category.includes(",") ? (
            <Stack
            sx={{
              maxWidth: "100%"
            }} spacing={1} direction={"row"}>
              {destination.category.split(",").map((tag) => {
                return <Tag key={tag} content={tag} color={PRIMARY_COLOR} />;
              })}
            </Stack>
          ) : (
            <Tag content={destination.category} color={PRIMARY_COLOR} />
          )
        ) : null}
      </Detail>
      {isTrashVisible && <DeleteButton onClick={handleDeleteDestination} />}
    </div>
  );
};

const DeleteButton = ({ onClick }) => {
  return (
    <FaTrashAlt
      className="absolute top-0 right-0 text-primary cursor-pointer"
      onClick={onClick}
    />
  );
};

const Detail = ({ children, displayTime }) => {
  const detailStyle = {
    display: "flex",
    flexDirection: "column",
    rowGap: "5px",
    justifyContent: "center",
    marginLeft: "15px",
    transform: displayTime ? "translateX(-40px)" : "translateX(0px)",
  };

  return <div style={detailStyle}>{children}</div>;
};

export default DestinationEdit;
