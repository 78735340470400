import React from "react";
import { useNavigate } from "react-router";

export const Logo = () => {
  const navigate = useNavigate();
  return (
    <p
      className="font-semibold text-lg"
      onClick={() => {
        navigate("/");
      }}
    >
      Eleplan.ai
    </p>
  );
};
