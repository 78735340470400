import React, { FC, ReactNode, useEffect, useMemo, useRef } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { toast } from "react-hot-toast";
import { signOut } from "../helper/auth";
import { css } from "@emotion/css";
import { useUser } from "../hooks/useUser";
import { FaBloggerB, FaSignInAlt, FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import CONSTANTS from "../constants";
import { useStripeCustomerInfo } from "../hooks/useStripeCustomerInfo";

export const NavBottomSheet: FC<{
  showSheet: boolean;
  setShowSheet: (prev: boolean) => void;
}> = ({ showSheet, setShowSheet }) => {
  const isDesktopUser = window.innerWidth >= 1024;

  async function handleSignOut() {
    try {
      await signOut();

      window.location.reload();
    } catch (error) {
      toast.error(error.message);
    }
  }

  if (isDesktopUser) {
    return showSheet && <DesktopDropDown setShowSheet={setShowSheet} />;
  }

  return (
    <MobileBottomSheet
      showSheet={showSheet}
      setShowSheet={setShowSheet}
      handleSignOut={handleSignOut}
    />
  );
};

const Menu: FC<{
  content: string;
  onClick?: any;
  icon?: ReactNode;
}> = ({ content, onClick, icon }) => {
  return (
    <div
      onClick={onClick}
      className={css`
        padding: 1rem 1.5rem;
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-weight: 500;
        color: black;
      `}
    >
      {icon}
      {content}
    </div>
  );
};

function MobileBottomSheet({ showSheet, setShowSheet, handleSignOut }) {
  const navigate = useNavigate();
  const { user, loading } = useUser();
  const { stripeCustomerInfo, isLoading } = useStripeCustomerInfo();

  if (isLoading) return null;

  return (
    <BottomSheet
      open={showSheet}
      onDismiss={() => {
        setShowSheet((prev: boolean) => !prev);
      }}
    >
      <div
        className={css`
          display: flex;
          flex-direction: column;
          padding: 2rem 0;
        `}
      >
        {loading ? null : user ? <Menu content={user.email!} /> : null}
        {!stripeCustomerInfo?.plan_active && (
          <button
            onClick={() => {
              window.location.assign(`https://blog.eleplan.ai/pricing`);
            }}
            className="border-2 border-solid border-primary focus:outline-none bg-white mx-5 mb-5"
          >
            Upgrade to pro
          </button>
        )}
        <Menu
          onClick={() => window.location.assign(CONSTANTS.ELEPLAN_BLOG_URL)}
          icon={<FaBloggerB />}
          content="Blogs"
        />
        <Menu
          onClick={
            user
              ? handleSignOut
              : () => {
                  navigate("/sign-in");
                }
          }
          icon={user ? <FaSignOutAlt /> : <FaSignInAlt />}
          content={user ? "Sign Out" : "Sign In"}
        />
      </div>
    </BottomSheet>
  );
}

function DesktopDropDown({ setShowSheet }) {
  const dropDownRef = useRef<HTMLUListElement | null>(null);
  const { user, loading } = useUser();
  const navigate = useNavigate();
  const { stripeCustomerInfo, isLoading } = useStripeCustomerInfo();

  useEffect(() => {
    function handleClickOutSide(event: any) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setShowSheet((_: any) => false);
      }
    }
    // Bind previous function to click event happens anywhere in the document
    document.addEventListener("mousedown", handleClickOutSide);

    // Clean up function (Execute after componentIsRemovedFromDOM or re-render)
    return () => {
      // Dispose the event listener from the document
      document.removeEventListener("mousedown", handleClickOutSide);
    };
  }, [dropDownRef]);

  const menus = useMemo(
    () => [
      {
        title: "Blogs",
        icon: <FaBloggerB />,
        onClick: () => {
          window.location.href = CONSTANTS.ELEPLAN_BLOG_URL;
        },
      },
      {
        title: user ? "Sign Out" : "Sign In",
        icon: user ? <FaSignOutAlt /> : <FaSignInAlt />,
        onClick: async () => {
          if (!user) return navigate("/sign-in");
          await signOut();
          // refresh
          window.location.reload();
        },
      },
    ],
    [user]
  );

  if (loading) return null;

  return (
    <ul
      ref={dropDownRef}
      className="absolute flex flex-col z-50 bg-white m-0 p-0 w-[200px] right-0 top-0 translate-y-[70px] shadow-lg rounded-xl overflow-hidden"
    >
      {menus.map((menu, index) => {
        return (
          <DropDownMenu
            onClick={menu.onClick}
            key={menu.title}
            menu={menu}
            index={index}
          />
        );
      })}
      {!stripeCustomerInfo?.plan_active && (
        <button
          onClick={() => {
            window.location.assign(`https://blog.eleplan.ai/pricing`);
          }}
          className="border-2 border-solid border-primary focus:outline-none bg-white w-[85%] mx-auto box-border text-sm mb-3 hover:bg-primary hover:text-white transition-all hover:outline-none hover:border-primary"
        >
          Upgrade to pro
        </button>
      )}
    </ul>
  );
}

function DropDownMenu({ onClick, menu, index }) {
  return (
    <motion.div
      initial={{
        translateY: 10,
      }}
      animate={{
        translateY: 0,
      }}
      transition={{
        type: "tween",
        delay: 0.2 * index,
      }}
    >
      <li
        onClick={onClick}
        className="flex gap-x-2 items-center p-5 m-0 font-medium h-8 hover:bg-primary hover:text-white cursor-pointer transition-all"
      >
        {menu.icon}
        <p>{menu.title}</p>
      </li>
    </motion.div>
  );
}
