import { createContext, useState } from "react";

type ContextProps = {
  showBottomSheet: boolean;
  showUserProfile: boolean;
  setShowBottomSheet: any;
  setShowUserProfile: any;
};

export const AppContext = createContext<ContextProps | null>(null);
