import { Checkbox, cn } from "@nextui-org/react";
import classNames from "classnames";
import React from "react";

const CategoryCard = ({ title, isSelected, onSelect }) => {
  const containerClass = classNames(
    "flex",
    "gap-x-2",
    "w-full",
    "h-[58px]",
    isSelected
      ? "bg-primary text-white"
      : "bg-white border border-solid border-gray-300 text-black",
    "rounded-xl",
    "items-center",
    "justify-start",
    "cursor-pointer",
    "transition-all",
    "overflow-hidden",
    "px-3"
  );
  return (
    <div onClick={onSelect} className={containerClass}>
      <Checkbox
        onClick={onSelect}
        classNames={{
          icon: "bg-white text-primary w-[20px] h-[20px] p-1",
        }}
        color="primary"
        radius="full"
        isSelected={isSelected}
      ></Checkbox>
      <p className="text-center font-semibold break-words">{title}</p>
    </div>
  );
};

export default CategoryCard;
