import axios from "axios";
import { TransportationDetail } from "../models/google/transportationDetail.model";
import { searchImageUnsplash } from "../services/unsplash.service";
import { doesSymbolExistInSentence, splitSentences } from "./util";

export async function getTransportationDetail(
  originDestinationId: string,
  targetDestinationId: string
): Promise<TransportationDetail | null> {
  try {
    //  Handle error if origin or target destination id is null
    if (!originDestinationId || !targetDestinationId)
      throw new Error("No origin or target destination id");
    const { data } = await axios.get(
      `${process.env.ITINERARY_MANAGEMENT_ENDPOINT!}/${process.env
        .API_VERSION!}/transportation-detail?originDestinationId=${originDestinationId}&targetDestinationId=${targetDestinationId}`
    );
    return data.result;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export async function getDestinationImageUrl(
  destinationName: string
): Promise<string> {
  try {
    const { data } = await searchImageUnsplash(destinationName);

    return data.results[0].urls.full;
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export function getDestinationMapUrl(destinationName: string): string {
  return `https://www.google.com/maps/place/?q=${destinationName}`;
}

// "Today is Month. Today of Nextday is Tomorrow. And Next day talks later ablut : ABC. - SO what to do next? - That's still unknown."
export function formatDestinationDescription(text: string): string[] {
  const puntucations = [".", "-"];

  const newLineSymbols = [":"];

  const sentences = splitSentences(text, puntucations);

  let result: string[] = [];

  let tempSentence: string = "";

  Object.entries(sentences).map(([sentence, punctuation], index) => {
    if (punctuation === "-") {
      result.push(tempSentence);
      tempSentence = "";
      tempSentence += `${punctuation} ` + sentence;
      result.push(tempSentence);
      tempSentence = "";
    } else if (doesSymbolExistInSentence(sentence, newLineSymbols)) {
      result.push(tempSentence);
      tempSentence = "";
      tempSentence += sentence + `${punctuation}`;
      result.push(tempSentence);
      tempSentence = "";
    } else {
      tempSentence += sentence + `${punctuation} `;
    }

    if (index === Object.entries(sentences).length - 1) {
      result.push(tempSentence.trimEnd());
    }
  });

  return result.filter((sentence) => Boolean(sentence));
}
