import React, { FC } from "react";
import classNames from "classnames";

type Props = {
  text: string;
  position?: "left" | "center" | "right";
};

const Title: FC<Props> = ({ text, position = "left" }) => {
  const containerClass = classNames("flex mt-5 items-center w-full", {
    "justify-start": position === "left",
    "justify-center": position === "center",
    "justify-end": position === "right",
  });
  return (
    <header className={containerClass}>
      <h1 className="text-title lg:text-3xl font-semibold text-black">
        {text}
      </h1>
    </header>
  );
};

export default Title;
