import { createBrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { ItineraryProvider } from "../contexts/ItineraryContext";
import App from "../routes/App";
import ConfirmDestinationsPage from "../routes/confirm-destinations/ConfirmDestinationsPage";
import CreateItinerary from "../routes/create-itinerary";
import ItineraryInformationPage from "../routes/itinerary-information/ItineraryInformationPage";
import ItineraryPage from "../routes/itinerary/ItineraryPage";
import SignInPage from "../routes/(auth)/sign-in/SignInPage";
import SignUp from "../routes/SignUp";
import GoogleSearch from "../routes/test/GoogleSearch";
import SignInWithEmailPage from "../routes/(auth)/sign-in/email/SignInWithEmailPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ),
  },
  {
    path: "/sign-in",
    element: (
      <ErrorBoundary>
        <SignInPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/sign-in/email",
    element: (
      <ErrorBoundary>
        <SignInWithEmailPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/sign-up",
    element: (
      <ErrorBoundary>
        <SignUp />
      </ErrorBoundary>
    ),
  },
  {
    path: "/test/google-search",
    element: (
      <ErrorBoundary>
        <GoogleSearch />
      </ErrorBoundary>
    ),
  },
  {
    path: "/itinerary-information",
    element: (
      <ErrorBoundary>
        <ItineraryInformationPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/create-itinerary",
    element: (
      <ErrorBoundary>
        <CreateItinerary />
      </ErrorBoundary>
    ),
  },
  {
    path: "/confirm-destinations",
    element: (
      <ErrorBoundary>
        <ConfirmDestinationsPage />
      </ErrorBoundary>
    ),
  },
  {
    path: "/itinerary",
    element: (
      <ErrorBoundary>
        <ItineraryProvider>
          <ItineraryPage />
        </ItineraryProvider>
      </ErrorBoundary>
    ),
  },
]);
