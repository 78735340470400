import { StarIcon } from "@heroicons/react/24/solid";
import { Stack } from "@mui/material";
import React, { FC } from "react";

const MAXIMUM_RATING = 5;

type Props = {
  rating: number;
};

const Rating: FC<Props> = ({ rating }) => {
  if (rating > MAXIMUM_RATING) return "Invalid rating";
  return (
    <Stack direction={"row"} alignItems={"center"} gap={0.25}>
      <StarIcon className="size-5 text-primary" />
      <p className="font-semibold text-[13px]">{rating}</p>
    </Stack>
  );
};

export default Rating;
