import React, { useState, useEffect, ChangeEvent } from "react";
import { fetchGooglePlaces } from "../helper/googlePlace.helper";
import { IoLocationSharp } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { FC } from "react";
import { toast } from "react-hot-toast";
import { Destination } from "../models/destination";

export const GoogleDestinationSearchBar: FC<{
  onAddDestination: any;
  currentDay: string;
  targetCountry: string;
}> = ({ onAddDestination, currentDay, targetCountry }) => {
  const [query, setQuery] = useState("");
  const [places, setPlaces] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(async () => {
      if (!query) {
        setLoading(false);
        return setPlaces(null);
      }
      try {
        const fetchedPlaces = await fetchGooglePlaces(query, [
          "id",
          "displayName",
          "formattedAddress",
          "currentOpeningHours",
          "editorialSummary",
          "primaryType",
          "photos",
          "plusCode"
        ], targetCountry);

        setPlaces((_) => fetchedPlaces);
      } catch (error: any) {
        toast.error("Failed while searching for places");
      }

      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [query]);

  function handleClearQuery() {
    setQuery("");
  }

  return (
    <div className="flex flex-col relative">
      <div className="flex gap-x-3 items-center border-2 border-primary w-full h-[50px] rounded-2xl px-4">
        <IoLocationSharp className="text-primary" size={25} />
        <input
          placeholder="Search for destinations..."
          className="border-none outline-none w-full bg-transparent"
          type="text"
          value={query}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setQuery(e.target.value)
          }
        />
        {query && (
          <MdCancel
            onClick={handleClearQuery}
            className="text-primary hover:scale-105"
            size={25}
          />
        )}
      </div>
      {loading ? (
        <ul className="flex gap-x-3 justify-center w-full rounded-xl bg-white shadow-md p-5">
          <span className="animate-spin h-6 w-6 rounded-full border-[3px] border-b-primary"></span>
          <p>Loading places...</p>
        </ul>
      ) : null}
      {places && (
        <ul className="flex flex-col m-0 w-full translate-y-16 shadow-md rounded-xl absolute bg-white overflow-hidden">
          {places.map((place) => (
            <PlaceItem
              onClearQuery={handleClearQuery}
              currentDay={currentDay}
              onAddDestination={onAddDestination}
              place={place}
              key={place.id}
            />
          ))}
        </ul>
      )}
    </div>
  );
};

const PlaceItem: FC<{
  place: Destination;
  onAddDestination: any;
  currentDay: string;
  onClearQuery: any;
}> = ({ place, onAddDestination, currentDay, onClearQuery }) => {
  const [hover, setHover] = useState(false);

  return (
    <li
      onClick={() => {
        onAddDestination(place, currentDay);
        onClearQuery();
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`w-full cursor-pointer p-4 ${
        hover ? "bg-primary text-white" : "bg-white text-black"
      } transition-all`}
    >
      <p className="font-semibold">{place.name_place}</p>
    </li>
  );
};
