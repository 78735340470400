import React, { ChangeEvent, useEffect, useState } from 'react'
import { GoogleDestinationSearchBar } from '../../components/GoogleDestinationSearchBar';
const GoogleSearch = () => {
  return (
    <div className='w-full h-full flex flex-col items-center justify-center'>
      <GoogleDestinationSearchBar />
    </div>
  )
}

export default GoogleSearch
