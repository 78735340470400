import { User } from "@supabase/supabase-js";
import { supabase } from "../configs/db";

export async function getUser(): Promise<User | null> {
  try {
    const {
      data: { user },
      error,
    } = await supabase.auth.getUser();
    if (error) throw new Error(error.message);
    return user;
  } catch (error: any) {
    throw new Error("Error while fetching current user");
  }
}
