import axios from "axios";
import { itineraryManagementClient } from "../configs/axios.config";

export async function getSuggestedDestinations(city, categories) {
  const endpoint = "https://urplantrip-plan-generator.vercel.app/";
  try {
    const { data } = await axios.get(
      `${endpoint}/destinations/suggests/${city}/${categories}`
    );
    return data;
  } catch (error) {
    throw new Error(error.message + "while getting suggested destinations");
  }
}

export async function saveItinerary(userId, trip) {
  try {
    await itineraryManagementClient.post(`/itinerary?userId=${userId}`, {
      trip,
    });
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function fetchTripById(tripId) {
  try {
    const response = await itineraryManagementClient.get(
      `/itinerary?tripId=${tripId}`
    );
    return response.data.itinerary;
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function updateTripById(tripId, newItinerary) {
  try {
    await itineraryManagementClient.put(`/itinerary?tripId=${tripId}`, {
      newItinerary,
    });
  } catch (error) {
    throw new Error(error.message);
  }
}

export async function deleteTripById(tripId) {
  try {
    await itineraryManagementClient.delete(`/itinerary?tripId=${tripId}`);
  } catch (error) {
    throw new Error(error.message);
  }
}
