export function splitSentences(
  text: string,
  criteriaPuntucations: string[]
): Record<string, string> {
  const sentences = text.split(
    new RegExp(`([${criteriaPuntucations.join("")}])`)
  );

  // Example of the result
  // {"Today is Month" : "."}
  // Record<setence | punctuation>

  let result: Record<string, string> = {};

  for (let index = 0; index < sentences.length; index++) {
    result[sentences[index]] = sentences[index + 1] as string;
  }

  return removeEmptyKeysOrValues(
    removeMatchedKeyFromObject(result, criteriaPuntucations)
  );
}

function removeMatchedKeyFromObject(
  object: Record<any, any>,
  criterias: string[]
) {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (!criterias.includes(key)) {
      acc[key.trim()] = value ?? "";
    }

    return acc;
  }, {} as Record<any, any>);
}

function removeEmptyKeysOrValues(
  obj: Record<string, string>
): Record<string, string> {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (key && value) {
      acc[key] = value;
    }

    return acc;
  }, {} as Record<string, string>);
}

// text is Hello, how are you doing?
// symbol is , and ?
export function doesSymbolExistInSentence(
  text: string,
  symbols: string[]
): boolean {
  return symbols.some((symbol) => text.includes(symbol));
}

export function formatDestinationWeekText(weekdayText: string): string[] {
  let day: string = "",
    time: string = "";

  const colonIndex = weekdayText.indexOf(":");
  day = weekdayText.slice(0, colonIndex);
  time = weekdayText.slice(colonIndex + 1);

  return [day, time];
}
