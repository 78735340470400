import React, { lazy } from "react";
import "../../App.css";
import SafeArea from "../../components/common/SafeArea";
import { useUser } from "../../hooks/useUser";

const NavBar = lazy(() => import("../../components/pages/home/Navbar"));
const ItineraryList = lazy(() => import("./_components/ItineraryList"));
const CuratedTravelExperiences = lazy(
  () => import("./_components/CuratedTravelExperiences")
);

function HomePage() {
  const { user } = useUser();
  return (
    <div className="flex flex-col justify-start items-center min-h-screen overflow-x-hidden w-full lg:px-[10%] overflow-scroll bg-[#FCFCFC] pt-2">
      <SafeArea>
        <NavBar user={user} />
      </SafeArea>
      <SafeArea>
        <ItineraryList />
      </SafeArea>
      <CuratedTravelExperiences />
    </div>
  );
}

export default HomePage;
