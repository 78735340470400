import React, { useState } from "react";
import HStack from "./HStack";
import { AnimatePresence, motion } from "framer-motion";
import { PRIMARY_COLOR } from "../assets/colors";

function Collapsable({ title, children, onClick, isExpanded = false, index }) {
  const iconStyle = {
    color: PRIMARY_COLOR,
    fontSize: "1.25rem",
    transform: `rotate(${isExpanded ? "-180" : "0"}deg)`,
    transition: "all 0.15s ease-in-out",
  };

  return (
    <motion.div
      initial={{
        opacity: 0,
        translateY: -10,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      transition={{
        type: "spring",
        duration: 1,
        delay: 0.2 * index,
      }}
    >
      <div
        className={`shadow-xl shadow-gray-100 px-5 rounded-2xl bg-white ${
          isExpanded ? "py-3" : ""
        }`}
      >
        <HStack
          className="cursor-pointer"
          onClick={() => {
            onClick();
          }}
          justify="space-between"
        >
          <h3 style={{ fontWeight: "600" }}>{title}</h3>
          <ion-icon style={iconStyle} name="chevron-down"></ion-icon>
        </HStack>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeInOut", duration: 0.2 }}
            >
              {children}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
}

export default Collapsable;
