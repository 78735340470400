import React from "react";

const LoadingDestinations = () => {
  return (
    <>
      <div className="h-[150px] min-w-[200px] lg:max-w-full lg:min-w-[300px] bg-white shadow-lg rounded-2xl animate-pulse my-4 p-4 flex flex-col justify-between overflow-hidden">
        <div className="w-full h-1/2 bg-gray-200 rounded-2xl animate-pulse"></div>
        <span className="w-full h-[20px] bg-gray-200 rounded-full"></span>
        <span className="w-2/3 h-[20px] bg-gray-200 rounded-full"></span>
      </div>
    </>
  );
};

export default LoadingDestinations;
