import { Stack } from "@mui/material";
import { CircularProgress } from "@nextui-org/react";
import React, { FC } from "react";

type Props = {
  message?: string;
};

const LoadingPage: FC<Props> = ({ message }) => {
  return (
    <Stack
      className="w-full h-full"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <CircularProgress label={message} />
    </Stack>
  );
};

export default LoadingPage;
