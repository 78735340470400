import { User } from "@supabase/supabase-js";
import React, { FC, useState } from "react";
import { Logo } from "../../Logo";
import { NavBottomSheet } from "../../NavBottomSheet";
import Hamburger from "../../common/Hamburger";
import UserProfile from "../../common/UserProfile";

type Props = {
  user: User | null;
};

const NavBar: FC<Props> = ({ user }) => {
  const [showUserProfile, setShowUserProfile] = useState(false);

  function handleUpdateShowUserProfile(value: boolean) {
    setShowUserProfile(value);
  }

  return (
    <nav className="relative flex items-center justify-between h-[40px] w-full">
      <Hamburger onClick={() => setShowUserProfile(true)} />
      <Logo />
      <UserProfile user={user} />
      <NavBottomSheet
        showSheet={showUserProfile}
        setShowSheet={handleUpdateShowUserProfile}
      />
    </nav>
  );
};

export default NavBar;
